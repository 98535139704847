import {requests} from "../../helpers/api.services";
import {SECTION_URL} from "../../helpers/api.routes";

export const AdminSectionService = {
    getAll: (params: any) => requests.get(SECTION_URL, params),
    getSingle: (id: number) => requests.get(`${SECTION_URL}/${id}`),
    create: (params: any) => requests.post(SECTION_URL, params, true),
    update: (params: any, id: number) =>
        requests.post(`${SECTION_URL}/${id}`, params, true),
    delete: (id: number) => requests.delete(`${SECTION_URL}/${id}`),
    deleteImage: (id: number)=> requests.delete(`${SECTION_URL}/image/${id}`)
}