import React, {useState} from 'react';
import {Form} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ErrorToast, SuccessToast} from "../../../shared/toasters/toasters";
import CreateForm from "./CreateForm";
import {AdminCarService} from "../service";

const UpdateCar = () => {
    const [image, setImage] = useState<File>();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [state, setState] = useState<any>();
    const [specificationData, setSpecificationData] = useState([])
    const submitHandler = () => {
        const formData = new FormData();
        formData.append("body", JSON.stringify({
            ...state, specificationData: specificationData
                ?.map((item: any) => ({id: item.id, key: item.key, value: item.value}))
        }));
        image && formData.append("image", image);
        AdminCarService.update(formData, state.id)
            .then((response) => {
                SuccessToast(t(`cars.successfully_updated`));
                navigate(-1);
                setState({});
            })
            .catch((error) => ErrorToast(error));
    };

    return (
        <div className="col-12">
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    submitHandler();
                }}
            >
                <CreateForm
                    state={state}
                    setState={setState}
                    image={image}
                    setImage={setImage}
                    specificationData={specificationData}
                    setSpecificationData={setSpecificationData}
                />
            </Form>
        </div>
    );
};

export default UpdateCar;