import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {AdminProductService} from "../../product/service";
import {ErrorToast, SuccessToast} from "../../../shared/toasters/toasters";
import {Loader} from "../../../shared/components/Loader";
import {EmptyState} from "../../../shared/components/EmptyState";
import {Table} from "react-bootstrap";
import {Link} from "react-router-dom";
import {ConfirmDelete} from "../../../shared/components/ConfirmDelete";
import {Pagination} from "../../../shared/paginations/Paginations";
import {AdminSectionService} from "../service";
import {clearHtmlFromString} from "../../../shared/functions/clearHtmlFromString";

const List = ({
                  list,
                  totalPages,
                  page,
                  setPagination,
                  handlePages,
                  editHandler,
              }: any) => {
    const {t} = useTranslation();
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [selectedPrompt, setSelectedPrompt] = useState<any>();

    const deleteHandler = () => {
        selectedPrompt?.id &&
        AdminSectionService.delete(selectedPrompt?.id)
            .then(() => {
                setPagination((prev: any) => ({...prev, updatedAt: new Date()?.getTime()}))
                SuccessToast(t(`sections.successfully_deleted`));
            })
            .catch((error) => ErrorToast(error));
    };

    return (
        <div className="card">
            <div className="card-header pb-0">
                <div className="d-flex justify-content-between">
                    <h4 className="card-title mg-b-0">{t("sections.listOfSections")}</h4>
                </div>
            </div>
            <div className="card-body">
                {list === undefined ? (
                    <Loader/>
                ) : list.length === 0 ? (
                    <EmptyState/>
                ) : (
                    <div className="table-responsive">
                        <Table className="align-middle table-nowrap mb-0">
                            <thead className="table-light">
                            <tr className="">
                                <th scope="col"> {t("global.id")}</th>
                                <th scope="col"> {t("global.title")}</th>
                                <th scope="col"> {t("global.text")}</th>
                                <th scope="col" className="text-end">
                                    {t("global.actions")}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {list?.map((prompt: any) => {
                                return (
                                    <tr key={prompt.id} className="">
                                        <td>
                                            <Link to="#" className="fw-medium">
                                                #{prompt?.id}
                                            </Link>
                                        </td>
                                        <td>{prompt.title ?? "-"}</td>
                                        <td className={'title-ellipsis'}>
                                            {clearHtmlFromString(prompt?.text ?? '-')}
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center justify-content-end">
                                                <button
                                                    className="btn btn-sm btn-warning d-flex align-items-center me-2"
                                                    onClick={() => editHandler(prompt.id!)}
                                                >
                                                    <i className="ri-edit-2-fill me-1"/>
                                                    {t("global.edit")}
                                                </button>
                                                <button
                                                    className="btn btn-sm btn-danger d-flex align-items-center"
                                                    onClick={() => {
                                                        handleShow();
                                                        setSelectedPrompt(prompt);
                                                    }}
                                                >
                                                    <i className="ri-delete-bin-fill me-1"/>
                                                    {t("global.delete")}
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </Table>
                    </div>
                )}

                <div className="d-flex justify-content-end">
                    {totalPages <= 1 ? (
                        ""
                    ) : (
                        <Pagination
                            page={page}
                            totalPages={totalPages}
                            handlePagination={handlePages}
                        />
                    )}
                </div>
                <ConfirmDelete
                    show={show}
                    setShow={setShow}
                    itemName={selectedPrompt?.title}
                    deleteHandler={deleteHandler}
                    product={selectedPrompt}
                    selectedProduct={selectedPrompt}
                />
            </div>
        </div>
    );
};

export default List;