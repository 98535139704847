import React, {useEffect, useState} from 'react';
import {Card, Col, Form, Row} from "react-bootstrap";
import {ErrorToast, SuccessToast} from "../../shared/toasters/toasters";
import {AdminProgramService} from "./service";
import {useTranslation} from "react-i18next";
import {types} from "sass";
import Error = types.Error;

interface IProgram {
    title: string;
    id?: number;
    programSchema?: IProgramSchema[]
}

interface IProgramSchema {
    indicator: number;
    id?: number;
    key: string;
    value: string;
    index?: string
}

const AdminProgram = () => {
    const {t} = useTranslation();
    const [state, setState] = useState<IProgram | null>(null);
    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setState((prev) => ({...prev, [name]: value}) as IProgram);
    };

    const changeSpecificationHandler = (event: React.ChangeEvent<HTMLInputElement>, schema: IProgramSchema) => {
        const {name, value} = event.target;
        const updated = state?.programSchema?.map(programSch => {
            if (programSch?.indicator === schema?.indicator) {
                return {
                    ...programSch,
                    [name]: value
                }
            }
            return programSch
        });
        setState(prev => ({...prev, programSchema: updated}) as IProgram)
    }

    const submitHandler = () => {
        const formData = new FormData();
        formData.append('body', JSON.stringify(state));
        AdminProgramService.create(formData)
            .then(response => {
                console.log(response)
                SuccessToast(t(`program.successfully_updated`));
            }).catch(err => ErrorToast(err));
    }

    useEffect(() => {
        AdminProgramService.get()
            .then((response) => {
                const {data} = response;
                setState({
                    ...data,
                    programSchema: data?.programSchema?.map((schema: IProgramSchema) => ({
                        ...schema,
                        indicator: schema?.id
                    }))
                });
            })
            .catch((error) => ErrorToast(error));
    }, []);

    return (
        <Card>
            <Card.Body>
                <Form
                    onSubmit={(e) => {
                        e.preventDefault()
                        submitHandler();
                    }}
                >
                    <Row className="my-3">
                        <Col md={4}>
                            <label htmlFor="name" className="required-field">
                                {t("global.title")}
                            </label>
                            <input
                                type="text"
                                value={state?.title}
                                name="title"
                                className="form-control"
                                required
                                onChange={changeHandler}
                            />
                        </Col>
                        <Col md={12} className={'mt-3'}/>
                        {state?.programSchema?.map(schema => {
                            return (
                                <React.Fragment key={schema?.indicator}>
                                    <Col md={5} className={'mb-2'}>
                                        <label htmlFor="key" className="required-field">
                                            {t("global.key")}
                                        </label>
                                        <input
                                            type="text"
                                            value={schema?.key}
                                            name="key"
                                            className="form-control"
                                            required
                                            onChange={(e) => changeSpecificationHandler(e, schema)}
                                        />
                                    </Col>
                                    <Col md={5} className={'mb-2'}>
                                        <label htmlFor="value" className="required-field">
                                            {t("global.value")}
                                        </label>
                                        <input
                                            type="text"
                                            value={schema?.value}
                                            name="value"
                                            className="form-control"
                                            required
                                            onChange={(e) => changeSpecificationHandler(e, schema)}
                                        />
                                    </Col>
                                    {schema?.id &&
                                        <Col md={2} className={'d-flex align-items-end mb-2'}>
                                            <button className="btn btn-sm btn-danger" type={'button'}>
                                                {t('global.delete')}
                                            </button>
                                        </Col>
                                    }
                                </React.Fragment>
                            )
                        })}
                        <Col md={12} className='d-flex mt-3 justify-content-end'>
                            <button type={'button'} className="btn btn-sm btn-success"
                                    onClick={() => setState((prev: any) => ({
                                        ...prev, programSchema: prev?.programSchema?.concat({
                                            key: '',
                                            value: '',
                                            indicator: Math.random()
                                        })
                                    }))}>
                                {t("program.add_new")}
                            </button>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <div className="d-flex justify-content-end text-align-center">

                            <button className="btn btn-primary" type="submit">
                                {t("global.save")}
                            </button>
                        </div>
                    </Row>
                </Form>

            </Card.Body>
        </Card>
    );
};

export default AdminProgram;