import React, {useEffect} from 'react';
import {Card, Col, Row} from "react-bootstrap";
import {PICTURE_URL} from "../../../helpers/api.routes";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ErrorToast} from "../../../shared/toasters/toasters";
import {AdminCarService} from "../service";
export interface ISpecification{
    indicator: number;
    id?: number;
    value: string;
    key: string;
}
const CreateForm = ({state, setState, image, setImage, specificationData, setSpecificationData}: any) => {
    const {id} = useParams();
    const {t} = useTranslation();
    const navigate = useNavigate();

    const changeHandler = (event: any) => {
        const {name, value} = event.target;
        setState((prev: any) => ({...prev, [name]: value}));
    };
    const fileHandler = (e: any) => {
        setImage(e.target.files[0]);
    };
    const switchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState((prev: any) => ({...prev, [e.target.name]: e.target.checked}));
    };

    const changeSpecificationHandler = (e:React.ChangeEvent<HTMLInputElement>, currId: number)=>{
        const {name, value} = e.target;
        const adjusted = specificationData?.map((data: ISpecification) => {
            if (data?.indicator === currId) {
                return {
                    ...data,
                    [name]: value,
                }
            }
            return data;
        })
        setSpecificationData(adjusted)
    }

    const handleDeleteSpecification = async (specification?: ISpecification) => {
        if (!specification?.id) {
            setSpecificationData((prev: ISpecification[]) => (prev || [])
                ?.filter((item: ISpecification) => item?.indicator !== specification?.indicator))
        } else {
            await AdminCarService.deleteSpecification(specification?.id)
                .then(response => {
                    if(response){
                        setSpecificationData((prev: ISpecification[]) => (prev || [])
                            ?.filter((item: ISpecification) => item?.indicator !== specification?.indicator))
                    }
                }).catch(err => ErrorToast(err));
        }
    }

    useEffect(() => {
        if (Number(id)) {
            AdminCarService.getSingle(Number(id))
                .then((response) => {
                    const {data} = response;
                    setState(data);
                    setSpecificationData(data?.specificationData
                        ?.map((item: ISpecification)=>({...item, indicator: item?.id})))
                })
                .catch((error) => ErrorToast(error));
        }
    }, [id]);
    return (
        <Card>
            <Card.Body>
                <Row>
                    <Col md={4}>
                        <label htmlFor="name" className="required-field">
                            {t("global.title")}
                        </label>
                        <input
                            type="text"
                            name="title"
                            className="form-control"
                            required
                            onChange={(e) => changeHandler(e as any)}
                            value={state?.id && state?.title}
                        />
                    </Col>
                    <Col md={2} className="">
                        <div className="form-check form-switch p-0">
                            <label
                                className="d-block text-start"
                                htmlFor="flexSwitchCheckDefault"
                            >
                                {t("global.electric")}
                            </label>
                            <div className="row">
                                <input
                                    className="form-check-input switch-custom ms-4 mt-2"
                                    type="checkbox"
                                    name={"electric"}
                                    checked={state?.electric}
                                    onChange={switchHandler}
                                    role="switch"
                                    id="flexSwitchCheckDefault"
                                />
                            </div>
                        </div>
                    </Col>
                    <Col md={2} className="">
                        <div className="form-check form-switch p-0">
                            <label
                                className="d-block text-start"
                                htmlFor="flexSwitchCheckDefault"
                            >
                                {t("global.active")}
                            </label>
                            <div className="row">
                                <input
                                    className="form-check-input switch-custom ms-4 mt-2"
                                    type="checkbox"
                                    name={ "isActive"}
                                    checked={ state?.isActive}
                                    onChange={switchHandler}
                                    role="switch"
                                    id="flexSwitchCheckDefault"
                                />
                            </div>
                        </div>
                    </Col>
                    <Col md={12} className={'mt-3'}/>
                    {specificationData?.map((specification: ISpecification) => {
                        return (
                            <React.Fragment key={specification?.indicator}>
                                <Col md={6} className={'mt-2'}>
                                    <label htmlFor="name" className="required-field">
                                        {t("global.key")}
                                    </label>
                                    <input
                                        type="text"
                                        name="key"
                                        className="form-control"
                                        required
                                        onChange={(e)=> changeSpecificationHandler(e, Number(specification?.indicator))}
                                        value={state?.id && specification?.key}
                                    />
                                </Col>
                                <Col md={6} className={'d-flex align-items-end mt-2'}>
                                    <div className={'w-100'}>
                                        <label htmlFor="name" className="required-field">
                                            {t("global.value")}
                                        </label>
                                        <input
                                            type="text"
                                            name="value"
                                            className="form-control"
                                            required
                                            onChange={(e)=> changeSpecificationHandler(e, Number(specification?.indicator))}

                                            value={state?.id && specification?.value}
                                        />
                                    </div>
                                    <button type={'button'} className="btn btn-danger btn-sm ms-1" onClick={() => handleDeleteSpecification(specification)}>
                                        {t('global.delete')}
                                    </button>
                                </Col>
                            </React.Fragment>
                        )
                    })}
                    <Col md={12} className={'d-flex justify-content-end mt-3'}>
                        <button className="btn btn-primary btn-sm" type={'button'}
                                onClick={() => setSpecificationData((prev: ISpecification[]) => (prev || []).concat({
                                    key: '',
                                    value: '',
                                    indicator: Math.random(),
                                }))}>
                            Add new specification
                        </button>
                    </Col>
                    <div className="mt-3">
                        <label htmlFor="imageFile"> {t("download.image")}</label>
                        <input
                            onChange={fileHandler}
                            className="form-control"
                            type="file"
                            accept="image/*"
                            id="imageFile"
                        />
                    </div>
                    {state?.image && (
                        <img
                            className="mt-3 rounded show-img-form"
                            alt="200x200"
                            src={PICTURE_URL + state?.image.path}
                            data-holder-rendered="true"
                        />
                    )}
                </Row>
                <div className="col-12 my-2 d-flex justify-content-end text-align-center">
                    <div>
                        <button
                            className="btn btn-info"
                            type={'button'}
                            onClick={() => {
                                navigate(-1);
                                setState({} as any);
                            }}
                        >
                            {t("global.cancel")}
                        </button>
                        <button className="btn btn-primary ms-3" type="submit">
                            {state?.id ? `  ${t("global.update")}` : `${t("global.create")}`}
                        </button>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
};

export default CreateForm;