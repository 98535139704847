import React, {useEffect, useState} from 'react';
import List from "./components/List";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {ErrorToast} from "../../shared/toasters/toasters";
import {AdminSectionService} from "./service";
import _debounce from "lodash/debounce";

const AdminSection = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [list, setList] = useState([]);
    const [pagination, setPagination] = useState({
        page: 1,
        perPage: 10,
    });
    const handlePages = (updatePage: number) => {
        setPagination({...pagination, page: updatePage});
        setPage(updatePage);
    };

    const editHandler = (id: number) => {
        navigate(`/admin/section/update/${id}`);
    };

    useEffect(() => {
        AdminSectionService.getAll(pagination)
            .then((response) => {
                const {items} = response;
                setList(items);
                setTotalPages(Math.ceil(response?.totalItems / response?.perPage));
            })
            .catch((error) => ErrorToast(error));
    }, [pagination]);

    return (
        <>
            <div className="col-12">
                <div className="card">
                    <div className="card-body d-flex align-items-center ">
                        <div className="d-flex align-items-center gap-3">
                            <div className="search-box">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="searchMemberList"
                                    onChange={_debounce((e: any) =>
                                        setPagination((prev: any) => ({
                                            ...prev,
                                            term: e.target.value,
                                        })), 300)
                                    }
                                    placeholder={t("global.search")}
                                />
                                <i className="ri-search-line search-icon"></i>
                            </div>
                        </div>
                        <button
                            className="btn btn-primary ms-auto"
                            onClick={() => navigate("/admin/section/create")}
                        >
                            {t("global.add")}
                        </button>
                    </div>
                </div>
            </div>

            <div className="col-12">
                <List
                    page={page}
                    handlePages={handlePages}
                    setPagination={setPagination}
                    totalPages={totalPages}
                    list={list!}
                    editHandler={editHandler}
                />
            </div>
        </>
    );
};

export default AdminSection;