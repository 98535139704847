import React, {useEffect, useRef, useState} from "react";
import {Button, Col, Row} from "react-bootstrap";
import {CustomImageHandler} from "../../../shared/components/CustomImageHandler";
import {useTranslation} from "react-i18next";
import Select from "react-select";
import {nanoid} from "nanoid";
// import { Editor } from "@tinymce/tinymce-react";
import DatePicker from "react-datepicker";
import {ErrorToast, SuccessToast} from "../../../shared/toasters/toasters";
import {AdminNewsService} from "../service";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {AdminBrandService} from "../../brand/service";
import {AdminCategoryService} from "../../category/service";
import {AdminNewsThemeService} from "../../newsTheme/service";
import {useParams} from "react-router-dom";
import Cropper, {ReactCropperElement} from "react-cropper";
import "cropperjs/dist/cropper.css";
import {PICTURE_URL} from "../../../helpers/api.routes";
// import { N1ED } from "@edsdk/n1ed-react";
// import { Editor } from "tinymce";
import axios from "axios";
import {ConfirmDelete} from "../../../shared/components/ConfirmDelete";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// @ts-ignore
// import Editor from "ckeditor5-custom-build/build/ckeditor";
import {InitEditor} from "../../tests/components/CreateForm";
import Compact from "@uiw/react-color-compact";

export interface NewsItem {
  id?: number;
  website: string;
  title: string;
  intro: string;
  content: string;
  date: string;
  hideDate: boolean;
  isVideoHeadLine: boolean;
  isComment: boolean;
  isPaidContent: boolean;
  isTransporterGuide: boolean;
  isPremium: boolean;
  isAraval: boolean;
  videoEmbed: string | null;
  script: string;
  mainImage?: {
    legend?: string;
    credit?: string;
    path?: string;
    link?: string;
    isTape?: boolean;
    tapeText?: string;
    color?: string;
  };
  isActive: boolean;
  redirectToUrl: string;
  isFeatured: boolean;
  subtitle: string;
  isKnowledgeNews: boolean;
  isEditorChoice: boolean;
  author: any;
  seoTitle: string;
  seoTags: string;
  seoDescription: string;
  theme: any;
  themeText: string;
  profilePicture: any;
  series: string;
  embeddedCode: string;
  link1: string;
  link2: string;
  link3: string;
  mailed: boolean;
  parent?: any;
  categories: any[];
  brands: any[];
  fileData: {
    id?: number | null;
    name?: string;
    path: string;
    legend: string;
    credit: string;
    index: null | number;
  }[];
  additionalFiles: any[];
}

interface Props {
  disabledSubmit: boolean;
  state: NewsItem;
  setState: React.Dispatch<React.SetStateAction<NewsItem>>;
  setFiles: any;
  files: any;
  additionalFiles: any;
  setAdditionalFiles: any;
  submitHandler: any;
}

export const CreateForm = ({
  state,
  setState,
  files,
  setFiles,
  disabledSubmit,
  setAdditionalFiles,
}: Props) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [mainImage, setMainImage] = useState<any>("");
  const [copyWebsite, setCopyWebsite] = useState<string>("");
  const [copyWebsiteMulti, setCopyWebsiteMulti] = useState<string>("");
  const [brandsList, setBrandsList] = useState<any[]>();
  const [selectedBrands, setSelectedBrands] = useState<any[]>([]);
  const [selectedPrompt, setSelectedPrompt] = useState<any>();
  const [categoryList, setCategoryList] = useState<any[]>();
  const [show, setShow] = useState<boolean>(false);
  const [selectedCategories, setSelectedCategories] = useState<any>([]);
  const [userList, setUserList] = useState<any[]>();
  const [editorData, setEditorData] = useState<any>();
  const [themesList, setThemesList] = useState<any[]>();
  const { website } = useSelector((state: RootState) => state.enum);
  const currentWebsite = localStorage.getItem("website");
  const currentTheme = useSelector((state: RootState) => state.theme);
  const parentNews = state?.parent ?? false;
  const firstLoad = useRef(true);
  const [selectEditor, setSelectEditor] = useState<boolean>(false);
  // const [isParentNews, setIsParentNews] = useState<any>(state?.parent);
  const [isAi, setIsAi] = useState(false);
  const mappedDataWebsite = website?.map((site: any) => ({
    label: site,
    value: site,
  }));
  const changeHandlerEditor = (value: string, eventName: string) => {
    setState((prev: any) => ({ ...prev, [eventName]: value }));
  };
  // const changeEditorHandler = (content: string, editor: Editor) => {
  //   setState((prev: any) => ({ ...prev, [editor?.id]: content }));
  //   if (!firstLoad.current) {
  //     setEditorData(editor);
  //   }
  //   firstLoad.current = false;
  // };
  const switchHandler = (e: any, nestedKey?: string) => {
    if (nestedKey?.length) {
      setState((prev: any) => ({
        ...prev, [nestedKey]: {
          ...prev[nestedKey],
          [e.target.name]: e.target.checked
        }
      }));
    } else {
      setState((prev: any) => ({...prev, [e.target.name]: e.target.checked}));
    }
  };

  const deleteAdditional = (selected: any) => {
    AdminNewsService.deleteAdditional(selected.id)
      .then(() => {
        setState((prev: any) => ({
          ...prev,
          additionalFiles: prev.additionalFiles.filter(
            (file: any) => file.id !== selected.id
          ),
        }));
        SuccessToast(t("global.successfully_deleted"));
      })
      .catch((err) => {
        ErrorToast(err);
      });
  };

  const switchHandlerRedirect = (e: any) => {
    setState((prev: any) => ({
      ...prev,
      ...prev,
      perent: {
        ...prev.perent,
        [e.target.name]: e.target.value,
      },
    }));
  };

  const handleChange = (i: any, e: any) => {
    let newFormValues = [...state.fileData];
    (newFormValues as any)[i][e.target.name] = e.target.value;

    setState((prev: any) => ({ ...prev, fileData: newFormValues }));
  };
  const copyHandler = (e: any) => {
    setCopyWebsite(e?.value);
  };
  const copyHandlerMulti = (selectedSingle: any) => {
    setCopyWebsiteMulti(
      selectedSingle?.map(
        (selectedSingle: { value: string }) => selectedSingle?.value
      )
    );
  };
  const brandsHandler = (e: any) => {
    setState((prev: any) => ({
      ...prev,
      brands: e.map((item: any) => item.value),
    }));

    setSelectedBrands(e);
  };

  const fileHandler = (e: any) => {
    setAdditionalFiles(e.target.files);
  };

  const categoriesHandler = (e: any) => {
    setState((prev: any) => ({
      ...prev,
      categories: e.map((item: any) => item.value),
    }));

    setSelectedCategories(e);
  };

  const selectSingleHandler = (e: any, name: string) => {
    setState((prev: any) => ({
      ...prev,
      [name]: e.value,
    }));
  };

  const mainChange = (e: any) => {
    setState((prev: any) => ({
      ...prev,
      mainImage: { ...prev.mainImage, [e.target.name]: e.target.value },
    }));
  };

  const copyNews = (e: any) => {
    e.preventDefault();
    AdminNewsService.copy(
      { websites: copyWebsiteMulti, ai: isAi },
      state?.id as number
    )
      .then((response) => {
        if (response?.status === "success") {
          SuccessToast(t(`Success copy news`));
        }
      })
      .catch((error) => ErrorToast(error));
  };
  const addFormFields = () => {
    setState((prev: any) => ({
      ...prev,
      fileData: [
        ...prev.fileData,
        {
          id: null,
          path: "",
          legend: "",
          credit: "",
          index: null,
        },
      ],
    }));
    const newFormData = [...state.fileData];
    const id = nanoid();
    newFormData.push({
      id: null,
      path: "",
      legend: "",
      credit: "",
      index: null,
    });
    setState((prev) => ({ ...prev, fileData: newFormData }));
  };
  const removeFormFields = (index: any) => {
    if (index === 0) {
      if (state?.fileData?.length === 1) {
        setState((prev: any) => ({
          ...prev,
          fileData: [
            {
              id: null,
              path: "",
              legend: "",
              credit: "",
              index: null,
            },
          ],
        }));
      } else {
        setState((prev) => ({
          ...prev,
          fileData: prev.fileData.filter((input, idx) => idx !== index),
        }));
      }
    } else {
      setState((prev) => ({
        ...prev,
        fileData: prev.fileData.filter((input, idx) => idx !== index),
      }));
    }
  };
  const changeHandler = (event: any) => {
    const { name, value } = event.target;
    setState((prev: any) => ({ ...prev, [name]: value }));
  };

  const changeNestedHandler = (event:React.ChangeEvent<HTMLInputElement>, nestedKey: string)=>{
    const {name, value} = event.target;
    setState((prev: any) => ({
      ...prev,
      [nestedKey]: {
        ...prev[nestedKey],
        [name]: value
      }
    }));

  }

  function handleDate(date: Date) {
    const value = new Date(date);
    value.setHours(12);
    setStartDate(date);
    setState((prev: any) => ({ ...prev, date: value }));
  }
  const cropperRef = useRef<ReactCropperElement>(null);

  const onCrop = () => {
    const cropper = cropperRef.current?.cropper;

    setState((prev: any) => ({
      ...prev,
      mainImage: {
        ...prev.mainImage,
        base64: cropper!.getCroppedCanvas()?.toDataURL(),
      },
    }));
  };

  const handleMainImage = (e: any) => {
    setMainImage(URL.createObjectURL(e.target.files[0]!));
  };

  const handleImageInput = (i: number, e: any) => {
    if (document.location.pathname.includes("create")) {
      if (e.target.files.length) {
        let newFormValuesImg = [
          ...state.fileData.map((file, index) =>
            index === i ? { ...file, index: files.length } : file
          ),
        ];

        (newFormValuesImg as any)[i]["path"] = URL.createObjectURL(
          e.target.files[0]
        );
        (newFormValuesImg as any)[i]["name"] = e.target.files[0].name;
        (newFormValuesImg as any)[i]["path"] = URL.createObjectURL(
          e.target.files[0]
        );
        let arrayFiles = files;
        let newItem = e.target.files[0];
        let position = i;
        arrayFiles[position] = newItem;
        setFiles(arrayFiles);
        setState((prev) => ({ ...prev, fileData: newFormValuesImg }));
      }
    } else {
      if (e.target.files.length) {
        let newFormValuesImg = [
          ...state.fileData.map((file, index) =>
            index === i ? { ...file, index: files.length } : file
          ),
        ];
        (newFormValuesImg as any)[i]["id"] = null;
        (newFormValuesImg as any)[i]["path"] = URL.createObjectURL(
          e.target.files[0]
        );
        (newFormValuesImg as any)[i]["name"] = e.target.files[0].name;
        let arrayFiles = files;
        let newItem = e.target.files[0];
        let position = i;
        arrayFiles[position] = newItem;
        setFiles(arrayFiles);
        // setFiles((prev: any) => [...prev, e.target.files[0]]);
        setState((prev) => ({ ...prev, fileData: newFormValuesImg }));
      }
    }
  };

  useEffect(() => {
    AdminBrandService.getAll({ perPage: 0, page: 1 })
      .then((response) => {
        const { data } = response;
        setBrandsList(
          data?.items.map((item: any) => ({
            label: item.name,
            value: item.id,
          }))
        );
      })
      .catch((error) => ErrorToast(error));
    AdminCategoryService.getAll({ perPage: 0, page: 1 })
      .then((response) => {
        const { data } = response;

        setCategoryList(
          data?.items.map((item: any) => ({
            label: item.name,
            value: item.id,
          }))
        );
      })
      .catch((error) => ErrorToast(error));
    AdminNewsService.getAllAuthors()
      .then((response) => {
        const { data } = response;

        setUserList(
          data?.map((item: any) => ({
            label: `${item.firstName} ${item.lastName}`,
            value: item.id,
          }))
        );
      })
      .catch((error) => ErrorToast(error));
    AdminNewsThemeService.getAll({ perPage: 0, page: 1 })
      .then((response) => {
        const { data } = response;

        setThemesList(
          data?.items.map((item: any) => ({
            label: item.title,
            value: item.id,
          }))
        );
      })
      .catch((error) => ErrorToast(error));
  }, []);
  const handleText = (content: string) => {
    setState((prev: any) => ({ ...prev, content }));
  };
  useEffect(() => {
    if (!!Number(id)) {
      AdminNewsService.getSingle(+id!)
        .then((response) => {
          const { data } = response;
          InitEditor(data?.content, handleText);
          setSelectedBrands(
            data?.brands.map((brand: any) => ({
              label: brand.name,
              value: brand.id,
            }))
          );
          setSelectedCategories(
            data?.categories.map((brand: any) => ({
              label: brand.name,
              value: brand.id,
            }))
          );

          setState({
            ...data,
            brands: data?.brands?.map((brand: any) => brand.id),
            categories: data?.categories?.map((category: any) => category.id),
            author: data?.author?.id,
            theme: data?.theme?.id,
            fileData:
              data?.fileData?.length === 0
                ? [
                    {
                      id: null,
                      name: "",
                      path: "",
                      legend: "",
                      credit: "",
                      index: null,
                    },
                  ]
                : data?.fileData,
          });
          data?.mainImage?.path == null
            ? console.log("mainImage err")
            : axios
                .get(
                  PICTURE_URL + data?.mainImage?.path,
                  { responseType: "arraybuffer" } // Set responseType to 'arraybuffer'
                )
                .then((response) => {
                  const blob = new Blob([response.data], {
                    type: response.headers["content-type"],
                  });
                  const url = URL.createObjectURL(blob);
                  setMainImage(url);
                });

          window.scrollTo(0, 0);
        })
        .catch((error) => {
          ErrorToast(error);
        });
    } else {
      InitEditor(undefined, handleText);
    }
  }, [id]);
  function removeElementsByClass(className: string) {
    const elements = document.getElementsByClassName(className);
    while (elements.length > 0) {
      elements && elements[0]?.parentNode?.removeChild(elements[0]);
    }
    setSelectEditor(false);
  }

  useEffect(() => {
    if (selectEditor) {
      removeElementsByClass(
        "jsplus_theme jsplus_ui_full_screen jsplus_ui_sidebar_widgets jsplus_show"
      );
    }
  }, [selectEditor]);

  return (
    <>
      <Row>
        <Col md={8}>
          <div className="card p-4">
            <div className="row my-2">
              <Col md={12}>
                <label htmlFor="title" className="required-field">
                  {t("global.title")}
                </label>
                <input
                  style={{ fontSize: "18px" }}
                  type="text"
                  name="title"
                  className="form-control"
                  required
                  onChange={(e) => changeHandler(e as any)}
                  value={state?.id && state?.title}
                />
              </Col>
              {!parentNews && (
                  <Col md={12} className="mt-2">
                    <label htmlFor="redirectToUrl" className="required-field">
                      {t("global.redirectToUrl")}
                    </label>
                    <input
                        id="redirectToUrl"
                        type="url"
                        name="redirectToUrl"
                        className="form-control"
                        onChange={(e) => changeHandler(e as any)}
                        value={state?.id && state?.redirectToUrl}
                    />
                  </Col>
              )}
            </div>
            <div className="row">
              <Col md={4}>
                <label htmlFor="theme" className="required-field">
                  {t("global.theme")}
                </label>
                <Select
                  id="theme"
                  className="react-select-field"
                  onChange={(e) => selectSingleHandler(e, "theme")}
                  options={themesList}
                  value={themesList?.find(
                    (theme: any) => theme.value === state?.theme
                  )}
                />
              </Col>
              <Col md={8}>
                <label htmlFor="theme_text" className="required-field">
                  {t("global.theme_text")}
                </label>
                <input
                  id="theme_text"
                  type="text"
                  name="themeText"
                  className="form-control"
                  onChange={(e) => changeHandler(e as any)}
                  value={state?.id && state?.themeText}
                />
              </Col>
            </div>
            <Row>
              <Col md={12} className="my-2">
                <div>
                  <label
                    htmlFor={`index-imageLegend-intro`}
                    className="form-label"
                  >
                    {t("global.intro")}
                  </label>
                  <textarea
                    className="form-control"
                    id={`index-imageLegend-intro`}
                    rows={3}
                    name="intro"
                    style={{ fontSize: "18px" }}
                    value={state?.intro}
                    onChange={(e) => changeHandler(e as any)}
                  ></textarea>
                </div>
              </Col>
            </Row>
            <div>
              <label className="d-block text-start" htmlFor="content">
                {t("global.content")}
              </label>
            </div>
            <textarea className="editor" onChange={console.log}></textarea>
            {/* <N1ED
              id={`content`}
              onFocus={(e) => setSelectEditor(true)}
              apiKey={process.env.REACT_APP_EDITOR_KEY}
              initialValue={
                editorData ? editorData : state ? state?.content : ""
              }
              onEditorChange={changeEditorHandler}
            /> */}
            {/* <CKEditor
              config={editorConfigurationCustom}
              editor={Editor}
              data={state?.content ?? ""}
              onChange={(event, editor) => {
                const data = editor.getData();
                changeHandlerEditor(data, "content");
              }}
            /> */}
            <Row>
              <Col md={12} className="my-2">
                <div>
                  <label htmlFor={`embedded_code`} className="form-label">
                    {t("table_headers.embedded_code")}
                  </label>
                  <textarea
                    className="form-control"
                    id={`embedded_code`}
                    rows={3}
                    name="embeddedCode"
                    value={state?.embeddedCode}
                    onChange={(e) => changeHandler(e as any)}
                  ></textarea>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="my-2">
                <div>
                  <label
                    htmlFor={`index-imageLegend-script`}
                    className="form-label"
                  >
                    {t("global.script")}
                  </label>
                  <textarea
                    className="form-control"
                    id={`index-imageLegend-script`}
                    rows={3}
                    name="script"
                    value={state?.script}
                    onChange={(e) => changeHandler(e as any)}
                  ></textarea>
                </div>
              </Col>
            </Row>
            <div className="">
              <div>
                <div className="form-inline">
                  <Row>
                    <label htmlFor="" className={state?.mainImage?.isTape ? 'required-field-news' : ''}> {t("news.main_image")}</label>
                    <Col md={12}>
                      <div className=" mt-2 mx-auto ">
                        <div className="card mb-0 p-3">
                          <div className="">
                            <div className="">
                              <div className="text-center">
                                <div className="profile-user position-relative d-inline-block mx-auto">
                                  {mainImage || state?.mainImage?.path ? (
                                    <CustomImageHandler
                                      path={
                                        mainImage
                                          ? mainImage
                                          : state?.mainImage?.path
                                      }
                                      classes="avatar-xl img-thumbnail user-profile-image"
                                      alt="user-profile-image"
                                    />
                                  ) : (
                                    <img
                                      src="/assets/images/img-placeholder.png"
                                      className="avatar-xl img-thumbnail user-profile-image"
                                      alt="user-profile-image"
                                    />
                                  )}
                                  <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                                    <input
                                      name="path"
                                      type="file"
                                      accept="image/*"
                                      id="main"
                                      // ref={imageRef}
                                      className="profile-img-file-input "
                                      onChange={(e) => handleMainImage(e)}
                                    />
                                    <label
                                      // htmlFor="profile-img-file-input"\
                                      htmlFor="main"
                                      className="profile-photo-edit avatar-xs"
                                    >
                                      <span className="avatar-title rounded-circle bg-light text-body">
                                        <i className="ri-camera-fill" />
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {mainImage && (
                            <Col md={12} className="mt-3">
                              <Cropper
                                src={mainImage}
                                style={{
                                  maxHeight: 400,
                                  width: "90%",
                                  margin: "0 auto",
                                }}
                                autoCropArea={1}
                                aspectRatio={1.461 / 1}
                                guides={false}
                                crop={onCrop}
                                ref={cropperRef}
                              />
                            </Col>
                          )}
                        </div>
                      </div>
                    </Col>

                    <Col md={12}>
                      <Col md={12} className="my-3">
                        <div>
                          <label
                            htmlFor={`index-imageLegend-`}
                            className="form-label"
                          >
                            {t("admin_event_summary.image_legend")}
                          </label>
                          <input
                            className="form-control"
                            id={`index-imageLegend-`}
                            name="legend"
                            value={state?.mainImage?.legend}
                            onChange={(e) => mainChange(e)}
                          />
                        </div>
                      </Col>
                      <Col md={12} className="my-3">
                        <div>
                          <label
                            htmlFor={`index-photoCredit-`}
                            className="form-label"
                          >
                            {t("global.photo_credit")}
                          </label>
                          <input
                            className="form-control"
                            id={`index-photoCredit`}
                            name="credit"
                            value={state?.mainImage?.credit}
                            onChange={(e) => mainChange(e)}
                          />
                        </div>
                      </Col>
                      <Col md={12} className="my-3">
                        <div>
                          <label htmlFor={`link`} className="form-label">
                            {t("global.link")}
                          </label>
                          <input
                            className="form-control"
                            id={`link`}
                            name="link"
                            value={state?.mainImage?.link}
                            onChange={(e) => mainChange(e)}
                          />
                        </div>
                      </Col>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>

            <div>
              <label>{t("global.otherImages")}</label>
              {state?.fileData?.map((element: any, index: any) => {
                return (
                  <div className="form-inline" key={element?.id}>
                    <Row>
                      <Col md={3}>
                        <div className=" mt-2 mx-auto ">
                          <div className="card mb-0">
                            <div className="card-body">
                              <div className="">
                                <div className="text-center">
                                  <div className="profile-user position-relative d-inline-block mx-auto">
                                    {(element as any)?.path ? (
                                      <CustomImageHandler
                                        path={
                                          element?.path
                                            ? (element as any)?.path
                                            : `${state?.profilePicture?.path}`
                                        }
                                        classes="avatar-xl img-thumbnail user-profile-image"
                                        alt="user-profile-image"
                                      />
                                    ) : (
                                      <img
                                        src="/assets/images/img-placeholder.png"
                                        className="avatar-xl img-thumbnail user-profile-image"
                                        alt="user-profile-image"
                                      />
                                    )}
                                    <div
                                      key={index}
                                      className="avatar-xs p-0 rounded-circle profile-photo-edit"
                                    >
                                      <input
                                        name="path"
                                        id={index as any}
                                        type="file"
                                        accept="image/*"
                                        // ref={imageRef}
                                        className="profile-img-file-input "
                                        onChange={(e) =>
                                          handleImageInput(index, e)
                                        }
                                      />
                                      <label
                                        // htmlFor="profile-img-file-input"\
                                        htmlFor={index as any}
                                        className="profile-photo-edit avatar-xs"
                                      >
                                        <span className="avatar-title rounded-circle bg-light text-body">
                                          <i className="ri-camera-fill" />
                                        </span>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md={9}>
                        <Col md={12} className="my-3">
                          <div key={index}>
                            <label
                              htmlFor={`index-imageLegend-${index}`}
                              className="form-label"
                            >
                              {t("admin_event_summary.image_legend")}
                            </label>
                            <input
                              className="form-control"
                              id={`index-imageLegend-${index}`}
                              name="legend"
                              value={element?.legend}
                              onChange={(e) => handleChange(index, e)}
                            />
                          </div>
                        </Col>
                        <Col md={12} className="my-3">
                          <div key={index}>
                            <label
                              htmlFor={`index-photoCredit-${index}`}
                              className="form-label"
                            >
                              {t("global.photo_credit")}
                            </label>
                            <input
                              className="form-control"
                              id={`index-photoCredit-${index}`}
                              name="credit"
                              value={element?.credit}
                              onChange={(e) => handleChange(index, e)}
                            />
                          </div>
                        </Col>
                      </Col>
                    </Row>
                    {index ? (
                      <>
                        <div className="d-flex justify-content-end">
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => removeFormFields(index)}
                          >
                            {t("global.remove")}
                          </button>
                          {index == state.fileData.length - 1 && (
                            <button
                              className="btn btn-primary ms-2"
                              type="submit"
                              onClick={() => {
                                addFormFields();
                              }}
                            >
                              {t("global.add_another")}
                            </button>
                          )}
                        </div>
                      </>
                    ) : index === 0 ? (
                      <div className="d-flex justify-content-end">
                        <button
                          disabled={
                            element?.name === "" &&
                            state?.fileData[index]?.credit === "" &&
                            state?.fileData[index]?.legend === ""
                          }
                          type="button"
                          className="btn btn-danger"
                          onClick={() => removeFormFields(index)}
                        >
                          {t("global.remove")}
                        </button>
                        {state.fileData.length === 1 && (
                          <button
                            disabled={element?.name === ""}
                            className="btn btn-primary ms-2"
                            type="submit"
                            onClick={() => {
                              addFormFields();
                            }}
                          >
                            {t("global.add_another")}
                          </button>
                        )}
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </div>
            <Row>
              <Col md={12} className="my-3">
                <div className="">
                  <label htmlFor="imageFile">
                    {t("global.additionalFiles")}
                  </label>
                  <input
                    onChange={fileHandler}
                    multiple={true}
                    className="form-control"
                    type="file"
                    id="imageFile"
                  />
                </div>

                <div className="row  mt-3  ">
                  {state.additionalFiles.map((file: any) => (
                    <Col md={12} className="d-flex align-items-center">
                      <i className="bi bi-file-earmark display-3"></i>
                      {file.originalName}
                      <i
                        className="ri-close-line ms-auto fs-22 cursor-pointer"
                        onClick={() => {
                          setSelectedPrompt(file);
                          setShow(true);
                        }}
                      ></i>
                    </Col>
                  ))}
                </div>
              </Col>
            </Row>
            <div className="d-flex justify-content-end align-itmes-center my-2">
              <Button
                className="btn btn-success"
                type="submit"
                disabled={disabledSubmit}
              >
                {state?.id ? "Edit News" : "Create News"}
              </Button>
            </div>
          </div>
        </Col>
        <Col>
          <div className="card p-4">
            <div className="row my-2">
              <Col md={8}>
                <label htmlFor="date" className="required-field">
                  {t("global.date")}
                </label>
                <div className="datepicker-container">
                  <DatePicker
                    className="date-picker custom_zindex"
                    dateFormat="dd/MM/yyyy"
                    selected={startDate}
                    value={state?.date}
                    onChange={(date) => handleDate(date as Date)}
                    isClearable
                    placeholderText="Choose date..."
                  />
                </div>
              </Col>
              <Col md={4} className=" mt-2">
                <label
                  className="d-block text-start"
                  htmlFor="flexSwitchCheckDefault"
                >
                  {t("global.hide_date")}
                </label>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input switch-custom"
                    type="checkbox"
                    name="hideDate"
                    checked={state?.hideDate}
                    onChange={switchHandler}
                    role="switch"
                    id="flexSwitchCheckDefault"
                  />
                </div>
              </Col>
            </div>
            <div className="row mb-2">
              <Col md={12} className="">
                <label htmlFor="author" className="required-field">
                  {t("global.author")}
                </label>
                <Select
                  className="react-select-field"
                  onChange={(e) => selectSingleHandler(e, "author")}
                  options={userList}
                  value={userList?.find(
                    (author: any) => author.value === state?.author
                  )}
                />
              </Col>
            </div>
            <div className="row my-2">
              <Col md={12} className="mb-2">
                <label htmlFor="category" className="required-field">
                  {t("table_headers.category")}
                </label>
                <Select
                  isMulti
                  className="react-select-field"
                  onChange={categoriesHandler}
                  options={categoryList}
                  value={selectedCategories}
                />
              </Col>
              <Col md={12} className="mb-2">
                <label htmlFor="brand" className="required-field">
                  {t("side_bar.brand")}
                </label>
                <Select
                  isMulti
                  className="react-select-field"
                  onChange={brandsHandler}
                  options={brandsList}
                  value={selectedBrands}
                />
              </Col>
            </div>
            <div className="row my-2">
              <Col md={12} className="mb-2">
                <label htmlFor="subtitle" className="required-field">
                  {t("global.link1")}
                </label>
                <input
                  type="text"
                  name="link1"
                  className="form-control"
                  onChange={(e) => changeHandler(e as any)}
                  value={state?.id && state?.link1}
                />
              </Col>

              <Col md={12} className="mb-2">
                <label htmlFor="subtitle" className="required-field">
                  {t("global.link2")}
                </label>
                <input
                  type="text"
                  name="link2"
                  className="form-control"
                  onChange={(e) => changeHandler(e as any)}
                  value={state?.id && state?.link2}
                />
              </Col>
              <Col md={12} className="mb-2">
                <label htmlFor="subtitle" className="required-field">
                  {t("global.link3")}
                </label>
                <input
                  type="text"
                  name="link3"
                  className="form-control"
                  onChange={(e) => changeHandler(e as any)}
                  value={state?.id && state?.link3}
                />
              </Col>
              <Col md={6} className=" mt-2">
                <label
                  className="d-block text-start"
                  htmlFor="flexSwitchCheckDefault"
                >
                  {t("global.active")}
                </label>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input switch-custom"
                    type="checkbox"
                    name="isActive"
                    checked={state?.isActive}
                    onChange={switchHandler}
                    role="switch"
                    id="flexSwitchCheckDefault"
                  />
                </div>
              </Col>
              <Col md={6} className="mt-2">
                <label
                  className="d-block text-start"
                  htmlFor="flexSwitchCheckDefault"
                >
                  {t("global.isVideoHeadLine")}
                </label>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input switch-custom"
                    type="checkbox"
                    name="isVideoHeadLine"
                    checked={state?.isVideoHeadLine}
                    onChange={switchHandler}
                    role="switch"
                    id="flexSwitchCheckDefault"
                  />
                </div>
              </Col>
              <Col md={6} className="mt-2">
                <label
                  className="d-block text-start"
                  htmlFor="flexSwitchCheckDefault"
                >
                  {t("global.isFeatured")}
                </label>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input switch-custom"
                    type="checkbox"
                    name="isFeatured"
                    checked={state?.isFeatured}
                    onChange={switchHandler}
                    role="switch"
                    id="flexSwitchCheckDefault"
                  />
                </div>
              </Col>
              <Col md={6} className="mt-2">
                <label
                  className="d-block text-start"
                  htmlFor="flexSwitchCheckDefault"
                >
                  {t("global.isKnowledgeNews")}
                </label>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input switch-custom"
                    type="checkbox"
                    name="isKnowledgeNews"
                    checked={state?.isKnowledgeNews}
                    onChange={switchHandler}
                    role="switch"
                    id="flexSwitchCheckDefault"
                  />
                </div>
              </Col>
              <Col md={6} className="mt-2">
                <label
                  className="d-block text-start"
                  htmlFor="flexSwitchCheckDefault"
                >
                  {t("global.isEditorChoice")}
                </label>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input switch-custom"
                    type="checkbox"
                    name="isEditorChoice"
                    checked={state?.isEditorChoice}
                    onChange={switchHandler}
                    role="switch"
                    id="flexSwitchCheckDefault"
                  />
                </div>
              </Col>
              <Col md={6} className="mt-2">
                <label
                  className="d-block text-start"
                  htmlFor="flexSwitchCheckDefault"
                >
                  {t("global.isComment")}
                </label>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input switch-custom"
                    type="checkbox"
                    name="isComment"
                    checked={state?.isComment}
                    onChange={switchHandler}
                    role="switch"
                    id="flexSwitchCheckDefault"
                  />
                </div>
              </Col>
              <Col md={6} className="mt-2">
                <label
                  className="d-block text-start"
                  htmlFor="flexSwitchCheckDefault"
                >
                  {t("global.isPaidContent")}
                </label>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input switch-custom"
                    type="checkbox"
                    name="isPaidContent"
                    checked={state?.isPaidContent}
                    onChange={switchHandler}
                    role="switch"
                    id="flexSwitchCheckDefault"
                  />
                </div>
              </Col>
              <Col md={6} className="mt-2">
                <label
                  className="d-block text-start"
                  htmlFor="flexSwitchCheckDefault"
                >
                  {t("global.isTransporterGuide")}
                </label>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input switch-custom"
                    type="checkbox"
                    name="isTransporterGuide"
                    checked={state?.isTransporterGuide}
                    onChange={switchHandler}
                    role="switch"
                    id="flexSwitchCheckDefault"
                  />
                </div>
              </Col>
              <Col md={6} className="mt-2">
                <label
                  className="d-block text-start"
                  htmlFor="flexSwitchCheckDefault"
                >
                  {t("global.isPremium")}
                </label>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input switch-custom"
                    type="checkbox"
                    name="isPremium"
                    checked={state?.isPremium}
                    onChange={switchHandler}
                    role="switch"
                    id="flexSwitchCheckDefault"
                  />
                </div>
              </Col>
              <Col md={6} className="mt-2">
                <label
                  className="d-block text-start"
                  htmlFor="flexSwitchCheckDefault"
                >
                  {t("global.isAraval")}
                </label>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input switch-custom"
                    type="checkbox"
                    name="isAraval"
                    checked={state?.isAraval}
                    onChange={switchHandler}
                    role="switch"
                    id="flexSwitchCheckDefault"
                  />
                </div>
              </Col>
            </div>
          </div>
          <div className="card p-4">
            <h5>{t("global.add_tape")}</h5>
            <div className="row d-flex justify-content-between">
              <Col md={12} className="mb-2">
                <label htmlFor="tapeText">
                  {t("global.tape_label")}
                </label>
                <input
                    type="text"
                    name="tapeText"
                    className="form-control"
                    onChange={(e) => changeNestedHandler(e as any, 'mainImage')}
                    value={state?.id && state?.mainImage?.tapeText}
                />
              </Col>
              <Col md={8} className="mb-3">
                <label htmlFor="color">
                  {t("global.tape_color")}
                </label>
                <Compact
                    style={{ marginLeft: 20 }}
                    color={state?.mainImage?.color}
                    onChange={(color) => {
                      setState((prev: any) => ({
                        ...prev, mainImage: {
                          ...prev.mainImage,
                          color: color.hex
                        }
                      }));
                    }}
                />
              </Col>
              <Col md={4} className="mt-2">
                <label
                    className="d-block text-start"
                    htmlFor="flexSwitchCheckDefault"
                >
                  {t("global.isTape")}
                </label>
                <div className="form-check form-switch">
                  <input
                      className="form-check-input switch-custom"
                      type="checkbox"
                      name="isTape"
                      checked={state?.mainImage?.isTape}
                      onChange={(e)=> switchHandler(e, 'mainImage')}
                      role="switch"
                      id="flexSwitchCheckDefault"
                  />
                </div>
              </Col>
              <p className={'text-muted'}>
                <span className={'text-danger'}>*</span>
                {t("global.tape_disclaimer")}
              </p>
            </div>
          </div>
          {parentNews && (
            <div className="card p-4">
              <Col md={6} className="mt-2">
                <label>{t("global.id")}</label>
                <input
                  disabled
                  type="text"
                  className="form-control"
                  value={state?.id && parentNews?.id}
                />
              </Col>
              <Col md={12} className="mt-2">
                <label>{t("global.title")}</label>
                <input
                  disabled
                  type="text"
                  className="form-control"
                  value={state?.id && parentNews?.title}
                />
              </Col>
              <Col md={12} className="mt-2">
                <label>{t("global.website_source")}</label>
                <input
                  disabled
                  type="text"
                  className="form-control"
                  value={
                    parentNews?.website &&
                    t(`enums.${(parentNews as any)?.website as string}`)
                  }
                />
              </Col>
              <Col md={12} className="mt-2">
                <label htmlFor="title" className="required-field">
                  {t("global.redirectToUrl")}
                </label>
                <input
                  type="url"
                  name="redirectToUrl"
                  className="form-control"
                  onChange={(e) => changeHandler(e as any)}
                  value={state?.id && state?.redirectToUrl}
                />
              </Col>
              <Col md={6} className="mt-2">
                <label
                  className="d-block text-start"
                  htmlFor="flexSwitchCheckDefault"
                >
                  {t("news.redirect_to_parent_news")}
                </label>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input switch-custom"
                    type="checkbox"
                    name="redirect"
                    checked={parentNews?.redirect}
                    onChange={switchHandlerRedirect}
                    role="switch"
                    id="flexSwitchCheckDefault"
                  />
                </div>
              </Col>
            </div>
          )}
          <div className="card p-4">
            <h5>{t("global.copyTo")}</h5>
            <div className="row d-flex align-items-end justify-content-between">
              <Col md={12}>
                <div className="mb-2">
                  <label htmlFor="website" className="required-field">
                    {t("table_headers.website")}
                  </label>
                  <Select
                    id="website"
                    className="react-select-field"
                    onChange={copyHandlerMulti}
                    isMulti={true}
                    options={(mappedDataWebsite as any)
                      ?.filter(
                        (website: any) => website.value !== currentWebsite
                      )
                      ?.map((site: any) => ({
                        value: site?.value,
                        label: t(`enums.${site?.label}`),
                      }))}
                  />
                </div>
              </Col>

              <Col md={3}>
                <div className="" style={{ marginBottom: 21 }}>
                  <label className="d-block text-start" htmlFor="ai">
                    {t("global.ai")}
                  </label>
                  <div className="form-check form-switch">
                    <input
                      id="ai"
                      checked={isAi}
                      className="form-check-input switch-custom"
                      type="checkbox"
                      name="ai"
                      role="switch"
                      onChange={(e) => setIsAi(e.target.checked)}
                    />
                  </div>
                </div>
              </Col>
              <Col md={3}>
                <div className="mb-2 ms-auto text-end">
                  <button
                    className="btn btn-info ms-auto"
                    onClick={(e) => copyNews(e)}
                  >
                    {t("news.copy")}
                  </button>
                </div>
              </Col>
            </div>
          </div>
          <div className="card p-4">
            <h5> {t("global.seo")}</h5>
            <Col md={12} className="my-2">
              <label htmlFor="seoTitle" className="required-field">
                {t("global.title")}
              </label>
              <input
                id="seoTitle"
                type="text"
                name="seoTitle"
                className="form-control"
                onChange={(e) => changeHandler(e as any)}
                value={state?.id && state?.seoTitle}
              />
            </Col>
            <Col md={12} className="my-2">
              <label htmlFor="seoTags" className="required-field">
                {t("global.tags")}
              </label>
              <input
                id="seoTags"
                type="text"
                name="seoTags"
                className="form-control"
                onChange={(e) => changeHandler(e as any)}
                value={state?.id && state?.seoTags}
              />
            </Col>
            <Col md={12} className="my-2">
              <label htmlFor="seoDescription" className="required-field">
                {t("global.description")}
              </label>
              <textarea
                id="seoDescription"
                name="seoDescription"
                rows={3}
                className="form-control"
                onChange={(e) => changeHandler(e as any)}
                value={state?.id && state?.seoDescription}
              />
            </Col>
          </div>
        </Col>
      </Row>
      <ConfirmDelete
        show={show}
        setShow={setShow}
        itemName={selectedPrompt?.name}
        deleteHandler={deleteAdditional}
        product={selectedPrompt}
        selectedProduct={selectedPrompt}
      />
    </>
    // <form>
    //   <label htmlFor="homepage">Add your homepage:</label>
    //   <input type="url" id="homepage" name="homepage" />
    //   <br />
    //   <br />
    //   <button type="submit" />
    // </form>
  );
};
